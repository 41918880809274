const webhooks = {
  main: 'https://discord.com/api/webhooks/1215353027553263687/t0BZiNhb5NIdmGntFGgZXdHleswSpLpGouvQgAtk7DQY0y5C77XMoEngzpLy-3XqQDpl',
  manage:
    'https://discord.com/api/webhooks/1236508685585350656/sJopxN0lyjjXXuSlSXmtlU2yDGqpBLr3-v6coZxV1Yj-4Tof-avLgzUkZAJlXTJ07yR5',
  hatagaya:
    'https://discord.com/api/webhooks/1215963173992267806/tpmeWH8F8q2L-dcjoruICZccVGoUtuM7qc6uOSqkcCD1xF-fz1DpvKVinwZt7AjfmuwY',
  audit:
    'https://discord.com/api/webhooks/1246268435227213894/84sTpYVWK-SK5kIUR9EEXB_1omw44Oh0RmPuXD8H07p71CQVOGEjBElndzktLMVKRi7l',
  trial:
    'https://discord.com/api/webhooks/1258282277138337895/9YCHGv_gsyImrYIqLpAlqxahEKy6jrySKiY_qE5ORlQGZ72Gv8SRBSjR6MzteXzKLNI3',
  payment:
    'https://discord.com/api/webhooks/1218592897029505034/O9oND3CuN0Bc5MYk2SjkhGv1fqgLq-m5c9EQC8agxlkpMZi6IS5yP099X6zLnUqoHAFr',
  reserve:
    'https://discord.com/api/webhooks/1217760107249795153/-_iAxvFOjHxrtQHMyzNh3ft8oO6x1NXPeePxO4w9msuBXPZtaPPZQ-nudGMU9R3DSDwr',
};

const instructorDiscordIds = {
  yuuki: '1243490102701719562',
  karin: '1226412066361835551',
  minami: '1215189312744722453',
  saki: '1269098987080781896',
};

const sendDiscordNotification = async (
  channelKey = 'main',
  embeds,
  mention = ''
) => {
  const webhook = webhooks[channelKey];
  if (!webhook) {
    console.error(`Invalid channel key: ${channelKey}`);
    return;
  }

  try {
    await fetch(webhook, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        content:
          mention === ''
            ? 'システムからの通知です'
            : `<@${instructorDiscordIds[mention]}>`,
        embeds: embeds,
      }),
    });
  } catch (error) {
    console.error(
      `ディスコードへの通知送信に失敗しました（チャンネル: ${channelKey}）:`,
      error
    );
  }
};

export { sendDiscordNotification };
